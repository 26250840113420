import { atom } from "jotai";
import { db } from "./firebase";

export function loadProject(id: string) {
  return db
    .collection("apps")
    .doc(id)
    .get()
    .then(v => {
      const data = v.data() as any;
      if (data.access === "hidden") {
        return data;
      }
    });
}

export function atomWithLocalStorage<T>(key: string, initialValue: T) {
  function getInitialValue(): T {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    }
    return initialValue;
  }
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    get => get(baseAtom),
    (get, set, update) => {
      const nextValue = typeof update === "function" ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      localStorage.setItem(key, JSON.stringify(nextValue));
    },
  );
  return derivedAtom;
}

import { motion } from "framer-motion";
import classes from "./TangleComponents.module.css";
import { TangleMsgBox } from "../webcomponents/dialog-component";
import { i18webcomponents } from "../webcomponents/dialog-component";

const primaryColor = "#ff8c5c";
const buttonColors: any = {
  primary: classes.primary,
  secondary: classes.secondary,
  danger: classes.danger,
  pinky: classes.pinky,
};

function TangleButton({ className, style, children, content, onClick, color, type, disabled }: any) {
  return (
    <motion.div whileTap={{ scale: 0.95 }} className="">
      <button className={`${classes.button} ${buttonColors[color || "primary"]} ${classes[type || "default"]} ${className}`} style={style} disabled={disabled} onClick={onClick}>
        {children || content}
      </button>
    </motion.div>
  );
}

function setTangleStyles() {
  TangleMsgBox.setStyles(`
   .tangle-msg-box-dialog-button:last-of-type {
         background: ${primaryColor} !important;
   }
    .tangle-msg-box-dialog-button:last-of-type:hover {
       background: ${primaryColor} !important;
    }
    .tangle-msg-box-dialog-header {
    }
    .tangle-msg-box-dialog-textbox {
    }
    .tangle-msg-box-dialog-header, .tangle-msg-box-dialog-body, .tangle-msg-box-dialog-footer  {
         background: #0a0c27 !important;
    }
    .tangle-msg-box-dialog-textbox {
         background: #1a1c34;
    }
    .tangle-msg-box-dialog-option {
         background: #1a1c34;
    }
    `);
}

const tanglePrompt = (item: any) =>
  TangleMsgBox.prompt(
    item?.description || "",
    item?.value || "",
    item?.title || "Prompt title",
    "",
    // @ts-ignore
    {},
    {
      confirm: item?.options?.confirm || "Potvrdit",
      cancel: item?.options?.cancel || null,
    },
  );

const tangleAlert = (item: any) =>
  TangleMsgBox.alert(
    "",
    item?.title || "Alert title", // title
  );

const TangleBox: any = {
  prompt: tanglePrompt,
  alert: tangleAlert,
};

export { setTangleStyles, TangleMsgBox, TangleBox, TangleButton };

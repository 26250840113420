import logo from "../assets/logo.png";

export default function SpectodaLoading() {
  return (
    <div className={`block bg-[#000422] fixed w-full h-full `}>
      <div className={"h-full w-full flex items-center justify-center z-100"}>
        <img
          src={logo}
          alt=""
          className="duration-200 animate-ballot"
          style={{ maxWidth: 250 }}
        />
      </div>
    </div>
  );
}

// @ts-nocheck
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import HttpApi from "i18next-http-backend";

import cs from "./_translations/cs.json";
import en from "./_translations/en.json";
// import en from "./preklady/nara/app/locales_new/en/translation.json";

i18n
  .use(LanguageDetector)
  // .use(HttpApi)
  .use(initReactI18next)
  .init({
    resources: {
      cs: { translation: cs },
      en: { translation: en },
      // sk: { translation: sk },
    },
    // backend: {
    //   loadPath: "https://translations.spectoda.com/nara/app/locales/{{lng}}/{{ns}}.json",

    //   // path to post missing resources, or a function
    //   // function(lng, namespace) { return customPath; }
    //   // the returned path will interpolate lng, ns if provided like giving a static path
    //   // addPath: "/locales/add/{{lng}}/{{ns}}",
    // },
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",
    supportedLngs: ["cs", "en"],
    nonExplicitSupportedLngs: false,
    interpolation: {},

    // have a common namespace used around the full app

    keySeparator: "_", // we use content as keys
    nsSeparator: "__",
    contextSeparator: "___",
    ns: "translation",

    interpolation: {
      escapeValue: false,
      defaultVariables: {
        name: process.env.REACT_APP_APPNAME,
      },
    },
  })
  .then(v => {});

// window.i18 = i18n;

export default i18n;

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { auth } from "firebaseui";
// var firebaseui = require('firebaseui');


// Your web app's Firebase configuration
var firebaseConfig = {
  // PUT YOUR FIREBASE CREDENTIALS HERE
  apiKey: "AIzaSyCnAfdv909gThutnCKwsoiNaSrHbqRUJv0",
  authDomain: "tangle-49512.firebaseapp.com",
  projectId: "tangle-49512",
  storageBucket: "tangle-49512.appspot.com",
  messagingSenderId: "707203240184",
  appId: "1:707203240184:web:d634fdd4b4c5fa2379eb0d",
  measurementId: "G-EJCQSBZ6YJ"
};
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

// firebase.analytics();

export const ui = new auth.AuthUI(firebase.auth());
export const storage = firebase.storage();


// ui.start()



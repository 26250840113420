import { useEffect, useState } from "react";
import { atomWithLocalStorage, loadProject } from "../utils/index";
import { useAtom, atom } from "jotai";
import { loadingAtom, phaseAtom } from "../App";
import { motion } from "framer-motion";
import { db } from "../utils/firebase";

// Components + Assets
import { TangleMsgBox, TangleButton, TangleBox, setTangleStyles } from "../components/TangleComponents";
import { CrossIcon, QuestionIcon, EditIcon } from "../components/Icons";
import SpectodaLoading from "../components/SpectodaLoading";
import { Dialog } from "@headlessui/react";
import { AppIcon, BROWSER_ICON_DATA_URI } from "../components/AppIcon";
import logo from "../assets/logo.png";
import { t } from "i18next";

export const appCodesAtom = atomWithLocalStorage("appcodes", []);
const noAppsAtom = atom(false);

setTangleStyles();

function NoApps() {
  const [, setNoApps] = useAtom(noAppsAtom);
  return (
    <div className="bg-[#000422] text-center text-white">
      <div className="flex justify-center min-h-screen w-full">
        <div className="container flex flex-col justify-start ">
          <img src={logo} alt="Logo" className="w-18 w-48 mt-16 mb-24 mx-auto" />
          <h2 className="text-2xl font-bold my-4">{t("Zatím nemáte spárované žádné zařízení...")}</h2>
          <p className="opacity-80">
            {t("Zkuste spárovat své zařízení stisknutím “Spárovat nové”. ")}
            {t("Ujistěte se, že je zařízení napájené a váš telefon má zapnuté Bluetooth.")}
          </p>
          <div className="mt-auto">
            <TangleButton className="btn btn-primary">{t("Spárovat nové")}</TangleButton>
            {/* <TangleButton
                     className="btn btn-empty"
                     onClick={() => setNoApps(false)}
                  >
                     DEMO: Mám 1+ aplikací
                  </TangleButton> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function AppList() {
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [appcodes, setAppCodes] = useAtom(appCodesAtom);
  const [apps, setApps] = useState<any>([]);
  const [userAddedApps, setUserAddedApps] = useState([]);
  const [activeApp, setActiveApp] = useState<string>("");

  const [, setPhase] = useAtom(phaseAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [noApps, setNoApps] = useAtom(noAppsAtom);

  const [, setLoadingApp] = useAtom(loadingAtom);

  async function openLink(url: string, protect: boolean, meta: any) {
    if (protect) {
      (async () => {
        let token;
        token = await TangleBox.prompt({
          title: t("Tato aplikace je chráněná,zadejte kód"),
        });
        while (!token || token.toUpperCase() !== "2104") {
          if (!token) {
            return;
          }
          await TangleBox.alert({ title: t("Kód není správný") });
          token = await TangleBox.prompt({
            title: t("Tato aplikace je chráněná,zadejte kód"),
          });
        }

        if (token === "2104") window.location.href = url;
      })();
    } else {
      setLoadingApp({ name: meta.name, icon: meta.icon, loading: true });
      window.location.href = url;
    }
  }

  useEffect(() => {
    appcodes
      .filter(x => x)
      .forEach((rawId: string) => {
        const id = rawId.trim();
        if (id.includes("https://") || id.includes("http://")) return;

        if (userAddedApps.find((x: any) => x.id == id)) return;
        try {
          loadProject(id).then(app => {
            // @ts-ignore
            setUserAddedApps(apps => [...apps, { ...app, id }]);
          });
        } catch (err) {
          console.error(t("App with ID:") + id + t(" could not be loaded. (Probably deleted)"));
        }
      });
    db.collection("apps")
      .where("access", "==", "public")
      .orderBy("name")
      .get()
      .then(query => {
        let docs: any = [];
        query.forEach(doc => docs.push(doc.data() as any));
        setApps(docs);
        setIsLoading(false);
      });
  }, [appcodes]);

  const handleAppRemoval = (app: any) => {
    const appId: string = typeof app === "string" ? app : app.id;
    setAppCodes((curr: any) => curr.filter((id: string) => id && id.trim() !== appId));
    setUserAddedApps((curr: any) => curr.filter(({ id }: any) => id !== appId));
    // console.log({ app, appId })
    // if (apps.length == 1) setNoApps(true);
  };

  const returnHelpIcon = () => <motion.div whileTap={{ scale: 1.05 }}>{isHelpOpen ? <CrossIcon /> : <QuestionIcon />}</motion.div>;

  const returnEditIcon = () => <motion.div whileTap={{ scale: 1.05 }}>{isEditingMode ? <CrossIcon /> : <EditIcon />}</motion.div>;

  const renderAppIframe = () => (
    <>
      <iframe title={activeApp} src={activeApp} className="absolute left-0 top-0 bottom-0 right-0 w-full h-full bg-[rgba(10,10,10,0.82)]"></iframe>
      <button onClick={() => setActiveApp("")} className="absolute right-10 top-10  p-5 rounded-full text-white font-bold text-xl z-10 bg-gray-600 hover:bg-gray-800">
        X
      </button>
    </>
  );

  console.log({ appcodes });
  if (isLoading) return <SpectodaLoading />;
  else if (noApps) return <NoApps />;
  else
    return (
      <>
        <Dialog open={isHelpOpen} onClose={() => {}} className="modal relative z-40">
          <div className="fixed inset-0 bg-[#000422cc]" aria-hidden="true" />
          <div className="fixed inset-0 flex flex-col p-6 items-center" onClick={() => setIsHelpOpen(false)}>
            <div className="container px-[20px]">
              <Dialog.Panel className="w-full mt-[74px] rounded-2xl bg-[#0C123D] text-white p-6">
                <Dialog.Title className="font-semibold mt-2 mb-3">{t("Nápověda")}</Dialog.Title>
                <p className="my-2">{t("V sekci “Moje aplikace” najdete všechna dostupná uživatelská rozhraní.")}</p>
                <p className="my-2">{t("Novou aplikaci si můžete přidat stisknutím tlačítka “Přidat novou” a zadáním přístupového kódu, který jste obdrželi od výrobce.")}</p>
                <p className="my-2">{t("Po rozkliknutí ikony aplikace budete automaticky přesměrováni do uživatelského rozhraní, kde najdete všechna dříve spárovaná Spectoda zařízení.")}</p>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>

        <div className="bg-[#000422] min-h-screen flex flex-col justify-start items-center text-white">
          <div className="flex justify-center min-h-screen w-full">
            <div className="container flex flex-col justify-between">
              <div className="flex w-full justify-between items-center mt-4">
                <h1 className="text-2xl font-semibold">{t("Moje aplikace")}</h1>

                <div className="flex">
                  <motion.button
                    className="rounded-full w-12 h-12 font-bold text-2xl bg-[#0C123D] grid place-items-center z-50 relative ml-2"
                    whileTap={{ scale: 1.05 }}
                    style={isEditingMode ? { opacity: 0.3 } : {}}
                    onClick={isEditingMode ? () => {} : () => setIsHelpOpen(curr => !curr)}
                  >
                    {returnHelpIcon()}
                  </motion.button>
                  <motion.button
                    className="rounded-full w-12 h-12 font-bold text-2xl bg-[#0C123D] grid place-items-center z-50 relative ml-2"
                    whileTap={{ scale: 1.05 }}
                    style={isHelpOpen ? { zIndex: 0 } : {}}
                    onClick={() => setIsEditingMode(curr => !curr)}
                  >
                    {returnEditIcon()}
                  </motion.button>
                </div>
              </div>
              <div className="w-full py-8 px-6  bg-[#1D203B] rounded-2xl mt-8 h-full">
                <div className="grid grid-cols-3 gap-5 justify-items-center">
                  {apps.map((app: any, i: any) => (
                    <AppIcon key={i} app={app} isEditingMode={isEditingMode} handleAppRemoval={() => handleAppRemoval(app)} openLink={() => openLink(app?.url, app?.protect, app)} />
                  ))}
                  {userAddedApps.map((app: any, i: any) => (
                    <AppIcon key={i} app={app} isEditingMode={isEditingMode} handleAppRemoval={() => handleAppRemoval(app)} openLink={() => openLink(app?.url, app?.protect, app)} />
                  ))}
                  {appcodes
                    .filter((code: string) => code.startsWith("https://") || code.startsWith("http://"))
                    .map((url: any, i: any) => (
                      <AppIcon
                        key={i}
                        app={{
                          name: url
                            .replace("http://", "")
                            .replace("https://", "")
                            .replace(/\/(.+)$/, ""),
                          url: url,
                          icon: BROWSER_ICON_DATA_URI,
                          access: "",
                          protect: false,
                        }}
                        isEditingMode={isEditingMode}
                        handleAppRemoval={() => handleAppRemoval(url)}
                        openLink={() =>
                          openLink(url, false, {
                            icon: BROWSER_ICON_DATA_URI,
                            name: url
                              .replace("http://", "")
                              .replace("https://", "")
                              .replace(/\/(.+)$/, ""),
                          })
                        }
                      />
                    ))}
                </div>
              </div>
              <div className="mt-auto " style={{ marginBottom: 12 }}>
                <TangleButton
                  className="btn  btn-empty"
                  onClick={async () => {
                    const answer = (await TangleMsgBox.prompt(t("Zadejte kód aplikace"), "", t("Zadejte kód") as string)) as string;

                    if (!answer) {
                      return;
                    }
                    try {
                      if ((appcodes as string[]).includes(answer)) {
                        TangleMsgBox.alert(t("Tato aplikace již existuje ve vašem seznamu."), t("Aplikaci již máte přidanou") as string);
                        return;
                      }
                      if (answer.includes("https://") || answer.includes("http://")) {
                        setAppCodes((apps: string[]) => [...apps, answer]);
                        return;
                      }
                      loadProject(answer)
                        .then(() => {
                          setAppCodes((apps: string[]) => [...apps, answer]);
                        })
                        .catch(err => {
                          TangleMsgBox.alert(t("Zkontrolujte zda jste zadali platný kód."), t("Nepovedlo se přidat aplikace") as string);
                        });
                    } catch (err) {}
                  }}
                >
                  {t("Přidat aplikaci")}
                </TangleButton>
                {/* <TangleButton className="btn btn-empty" onClick={() => setPhase(0)}>
                  {t("Zpět na úvod")}
                </TangleButton> */}
              </div>
            </div>
          </div>
          {activeApp && (
            <>
              <iframe title={activeApp} src={activeApp} className="absolute left-0 top-0 bottom-0 right-0 w-full h-full bg-[rgba(10,10,10,0.82)]"></iframe>
              <button onClick={() => setActiveApp("")} className="absolute right-10 top-10  p-5 rounded-full text-white font-bold text-xl z-10 bg-gray-600 hover:bg-gray-800">
                X
              </button>
            </>
          )}
        </div>
      </>
    );
}

export default AppList;

export const EditIcon = () => (
   <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M14.0473 2.715C14.2599 2.50241 14.5123 2.33377 14.79 2.21871C15.0678 2.10365 15.3655 2.04443 15.6662 2.04443C15.9668 2.04443 16.2645 2.10365 16.5423 2.21871C16.8201 2.33377 17.0725 2.50241 17.2851 2.715C17.4977 2.9276 17.6663 3.17999 17.7814 3.45776C17.8964 3.73553 17.9556 4.03325 17.9556 4.33391C17.9556 4.63456 17.8964 4.93228 17.7814 5.21005C17.6663 5.48782 17.4977 5.74021 17.2851 5.95281L6.3575 16.8804L1.90552 18.0946L3.11969 13.6426L14.0473 2.715Z"
         stroke="white"
         strokeWidth="1.6189"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
   </svg>
);

export const CrossIcon = ({ size }: any) => (
   <svg
      width={size || 28}
      height={size || 28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M20.8337 6.94434L6.94458 20.8334"
         stroke="white"
         strokeWidth="3"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M20.8337 20.8335L6.94458 6.9444"
         stroke="white"
         strokeWidth="3"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
   </svg>
);

export const QuestionIcon = () => (
   <svg
      width="18"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M7.47198 0.374023C4.53222 0.374023 2.62813 1.52374 1.13322 3.57416C0.862038 3.94611 0.945643 4.45609 1.32393 4.72988L2.91272 5.87981C3.29476 6.15631 3.83834 6.09166 4.13744 5.73398C5.06 4.63074 5.74434 3.99554 7.1854 3.99554C8.31845 3.99554 9.71992 4.6916 9.71992 5.74038C9.71992 6.53322 9.03425 6.9404 7.91549 7.53911C6.61085 8.23732 4.88439 9.10624 4.88439 11.2799V11.624C4.88439 12.09 5.28013 12.4678 5.76832 12.4678H8.43745C8.92564 12.4678 9.32138 12.09 9.32138 11.624V11.4211C9.32138 9.91427 13.935 9.85152 13.935 5.77402C13.9351 2.70334 10.5982 0.374023 7.47198 0.374023ZM7.10291 13.5034C5.69613 13.5034 4.55163 14.5959 4.55163 15.9388C4.55163 17.2815 5.69613 18.374 7.10291 18.374C8.50968 18.374 9.65418 17.2815 9.65418 15.9387C9.65418 14.5959 8.50968 13.5034 7.10291 13.5034Z"
         fill="white"
      />
   </svg>
);
